import './heroslick.css';
import styles from './styles.module.css'
import MyButton from '../MyButton/Index';
import { useEffect, useRef, useState } from "react";
// import { server } from "utils/index";
import MyImage from "Components/MyImage/Index";

const heroItems = [
    {
        id: "001",
        number: 1,
        heading: "Welcome to Nepal School Of Hotel Management",
        details: `we invite you to embark on an enriching journey in Hotel Management,blending theory with hands-on experience for a transformative learning experience.`,
        link: "/about",
        buttonText: 'About Us',
        images: ['/Images/LandingHero/bakery15.jpeg', '/Images/LandingHero/train1.jpeg', '/Images/LandingHero/we1.jpeg', '/Images/LandingHero/train4.jpeg']
    },
    {
        id: "002",
        number: 2,
        heading: "We Provide Hotel Management Courses",
        details: `Our Hotel Management courses offer a practical and comprehensive learning experience led by experienced instructors.`,
        link: "/courses",
        buttonText: 'Our Courses',
        images: ['/Images/LandingHero/food2.jpeg', '/Images/LandingHero/hero8.jpg', '/Images/LandingHero/hero1.jpg', '/Images/LandingHero/food5.jpeg']


    },
    {
        id: "003",
        number: 3,
        heading: "Feel Free To Contact Us For Any Queries",
        details: `Feel free to reach us regarding our courses and other ventures. our team will get back to you as soon as possible.`,
        link: "/contact",
        buttonText: 'Contact Us',
        images: ['/Images/LandingHero/bakery8.jpeg', '/Images/LandingHero/sushi25.jpeg', '/Images/LandingHero/sushi23.jpeg', '/Images/LandingHero/query1.jpeg']


    }
]

const LandingHero = () => {
    // const [heroImages, setHeroImages] = useState<any>([])
    // useEffect(() => {
    //     heroItems.map((hi) => {
    //         let cc = hi.images.map((him => {

    //             return setHeroImages((prev: any) => [...prev, {
    //                 image: him,
    //                 number: hi.number
    //             }])

    //         }))
    //     })
    // }, [heroItems, heroItems.length])
    // const { sliders, category } = useContext<any>(FeaturesContext)
    // const [mySection, setMySection] = useState<any>()
    // const [mySlider, setMySlider] = useState<any>([])
    // const [myCat, setMyCat] = useState<any>()

    // useEffect(() => {
    //     if (category && category.length > 0) {
    //         const cc = category.find((sc: any) => sc.name.toLowerCase() == 'hero slider')
    //         if (cc) {
    //             setMyCat(cc)
    //         } else {
    //             setMyCat(null)
    //         }
    //     }
    // }, [category, category.length])
    // useEffect(() => {
    //     if (myCat && sliders && sliders.length > 0) {
    //         const cc = sliders.filter((sc: any) => sc.category == myCat.id)
    //         if (cc && cc.length > 0) {
    //             setMySlider(cc)
    //         } else {
    //             setMySlider([])
    //         }
    //     }
    // }, [myCat, sliders, sliders.length])
    // var settings = {
    //     dots: false,
    //     arrows: true,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     autoplay: false,
    //     autoplaySpeed: 1500,
    //     // fade: true
    // };
    // console.log(heroImages)


    const [position, setPosition] = useState<number>(0)
    let currentInterval = useRef<any>(null)
    const timer = useRef<number>(0)
    useEffect(() => {
        currentInterval.current = setInterval(() => {
            timer.current = timer.current + 1
            timer.current = timer.current % 3
            setPosition(timer.current)
        }, 4000)
    }, [])
    // const onChange = (place: number) => {
    //     if (place < 1 && timer.current === 0) {
    //         timer.current = 3
    //     }
    //     console.log("timer current", timer.current)
    //     timer.current = Math.abs(timer.current + place)
    //     console.log("added timer", timer.current)
    //     timer.current = timer.current % 3
    //     console.log("mod timer", timer.current)

    //     setPosition(timer.current)
    //     clearInterval(currentInterval.current)
    //     currentInterval.current = setInterval(() => {
    //         timer.current = timer.current + 1
    //         timer.current = timer.current % 3
    //         setPosition(timer.current)

    //     }, 30000)
    // }
    return (
        <section className={`section ${styles.landingHeroSection}`}>
            <div className={`${styles.landingHeroInner}`}>
                <div className={`${styles.sliderItem}`}>
                    {heroItems.sort((a, b) => a.number - b.number).reverse().map((hi: any, index: number) => {

                        return <div className={styles.imageItem}
                            key={index}
                            style={{
                                opacity: position == hi.number - 1 ? "1" : "0",
                                zIndex: position == hi.number - 1 ? "3" : "-1",
                                transform: position == hi.number - 1 ? `translateY(0)` : `translateY(${hi.number - 1 % 2 == 0 ? '100px' : '-100px'})`,
                                width: "100%", objectFit: "cover",
                                transitionDelay: `${index * 100}ms`
                            }}
                        >
                            {
                                hi.images.map((him: any, ind: number) => {
                                    return <MyImage
                                        key={ind}
                                        source={him}
                                        alt='Hero Image'
                                        className={styles.heroImage}
                                        wrapperName={styles.imageWrapper}

                                    />

                                })
                            }

                        </div>

                    })}
                    <div className={styles.layover}></div>
                    <div className={`container ${styles.left}`}>
                        <div className={`${styles.leftInner}`}>
                            {heroItems.map((hi, index) => {
                                return <div className={styles.heroTextContainer}
                                    style={{

                                        opacity: position == hi.number - 1 ? "1" : "0",
                                        zIndex: position == hi.number - 1 ? "11" : "10",
                                    }}
                                >
                                    <div className={styles.heroTextInner}>
                                        <h1 className='section-heading'
                                            key={index}

                                        >{hi.heading}</h1>
                                        <p className='section-paragraph'
                                            key={index}

                                        >
                                            {hi.details}
                                        </p>
                                        <div className={styles.heroButton}>
                                            <MyButton text={hi.buttonText} link={true} href={hi.link} />
                                        </div>

                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default LandingHero