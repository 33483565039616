import { FeaturesContext } from 'Context/FeaturesContext/FeaturesContext'
import styles from './styles.module.css'
import MyButton from 'Components/MyButton/Index'
import { useContext, useEffect, useState } from 'react'
import { server } from 'utils/index'

import { useForm, SubmitHandler } from "react-hook-form"

import { message } from 'antd'
import CertificateForm from 'Components/CertificateForm/Index'

export const metadata = {
  title: 'Investments - Maitricraft Nepal',
  description: "Feel free to contact us for any queries, project collaborations, or to discuss how we can help you achieve your goals. Our team is excited to hear from you and explore the endless possibilities of working together. Let's turn your visions into reality!",
  openGraph: {
    images: "/Images/Contact/contact.png",
  },
}
type Inputs = {
  name: string,
  email: string,
  enrolledDate: string,
  imessage: string,
  phone: number,
  address: string,
  course: string
}

const Certificate = () => {
  const { sections, category } = useContext<any>(FeaturesContext)
  const [mySection, setMySection] = useState<any>()
  useEffect(() => {
    if (sections && sections.length > 0) {
      const cc = sections.find((sc: any) => sc.name.toLowerCase() == 'certificate hero')
      if (cc) {
        setMySection(cc)
      } else {
        setMySection(null)
      }
    }
  }, [sections, sections.length])


  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Inputs>()

  const onSubmit: SubmitHandler<Inputs> = async ({ name, email, phone, enrolledDate, imessage, address, course }) => {
    try {
      const res = await fetch(`${server}/inquiry`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          email,
          phone,
          address,
          course,
          sendWhatsapp: true,
          enrolledDate,
          message: imessage,
          category: category.find((cat: any) => cat.type == 'inquiry' && cat.name == 'Certificate Inquiry').id,
          project: "nshm"
        }),
      });
      const data = await res.json();
      if (res.status == 200 || res.status == 201) {
        reset()
        return message.success('We have received your certificate inquiry! Our team will reach you as soon as possible!');
      }
      return message.error(data.message);
    } catch (error) {
      console.log("error", error)
      message.error(`Sending certificate inquiry failed!`);
    }

  }
  return (
    <main className={styles.certificatePage}>
      <div className={styles.left}>
        <section className={styles.investmentHero}>
          <div className={`container ${styles.investmentHeroInner}`}>
            <div className={styles.heroLeft}>

              <h2 className='section-heading'>{mySection && mySection.heading}
              </h2>
              <p className='section-paragraph'>
                {mySection && mySection.paragraph}
              </p>
              <CertificateForm />


            </div>

            <div className={styles.heroRight}>
              <img
                src={`${mySection ? `${server}/${mySection && mySection.image}` : '/Images/LandingCertificate/certificate.avif'}`}
                alt='Contact Image'
                height={600}
                width={600}
              />

            </div>
          </div>
        </section>
        <section className={styles.investmentForm}>
          <div className={`container ${styles.investmentFormInner}`}>
            <h2 className='section-heading'>Request Certificate</h2>
            <div className="section-paragraph">If you can't find your certificate, you can request us.
              We will validate your information and reach to you shortly.</div>
            <div className={styles.investmentFormContainer}>
              <form action="post" onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.inputContainer}>
                  <label htmlFor="name">Name</label>
                  <span className={styles.required}>*</span>
                  <input type="text" placeholder='Enter Your Name' {...register("name", { required: true })} />
                  {errors.name && <span className={styles.errorText}>Name required</span>}

                </div>
                <div className={styles.inputContainer}>
                  <label htmlFor="email">Email</label>
                  <span className={styles.required}>*</span>
                  <input type="text" placeholder='Enter Your Email' {...register("email", { required: true })} />
                  {errors.email && <span className={styles.errorText}>Email required</span>}

                </div>

                <div className={styles.inputContainer}>
                  <label htmlFor="address">Address</label>
                  <span className={styles.required}>*</span>
                  <input type="text" placeholder='Enter Your Address' {...register("address", { required: true })} />
                  {errors.address && <span className={styles.errorText}>Address required</span>}

                </div>
                <div className={styles.inputContainer}>
                  <label htmlFor="phone">Phone</label>
                  <span className={styles.required}>*</span>
                  <input type="number" placeholder='Enter Your Phone' {...register("phone", { required: true })} />
                  {errors.phone && <span className={styles.errorText}>Phone required</span>}

                </div>


                <div className={styles.inputContainer}>
                  <label htmlFor="course">Course Name</label>
                  <span className={styles.required}>*</span>
                  <input type="text" placeholder='Enter Course Name' {...register("course", { required: true })} />
                  {errors.course && <span className={styles.errorText}>Course name required</span>}

                </div>
                <div className={styles.inputContainer}>
                  <label htmlFor="enrolledDate">Enrolled Date</label>
                  <span className={styles.required}>*</span>
                  <input type="date" placeholder='Enter Your Enrolled Date' {...register("enrolledDate", { required: true })} />
                  {errors.enrolledDate && <span className={styles.errorText}>Enrolled date required</span>}
                </div>
                <div className={styles.inputContainer}>
                  <label htmlFor="message">Message</label>
                  <span className={styles.required}>*</span>
                  <textarea placeholder='Enter Your Message' {...register("imessage", { required: true })} />
                  {errors.imessage && <span className={styles.errorText}>Message required</span>}

                </div>
                <div className={styles.inputContainer}>
                  <MyButton text='Send' type="submit" />
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
      <div className={styles.right}>
        <div className={styles.rightBox}>

        </div>
      </div>
    </main>
  )
}

export default Certificate