import styles from './styles.module.css'
// import { Rating } from '@smastrom/react-rating'
import Slider from 'react-slick';
import './reviewsslick.css'

// import '@smastrom/react-rating/style.css'
import { useContext, useEffect, useState } from 'react'
import { server } from 'utils/index'
import MyButton from 'Components/MyButton/Index';
import { FeaturesContext } from 'Context/FeaturesContext/FeaturesContext';

const LandingReviews = ({ }: any) => {
    const { sections } = useContext<any>(FeaturesContext)
    const [mySection, setMySection] = useState<any>()
    useEffect(() => {
        if (sections && sections.length > 0) {
            const cc = sections.find((sc: any) => sc.name.toLowerCase() == 'landing reviews title')
            if (cc) {
                setMySection(cc)
            } else {
                setMySection(null)
            }
        }
    }, [sections, sections.length])
    var settings = {
        dots: false,
        arrows: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 2000,
        centerMode: true,
        cssEase: 'linear',
        responsive: [

            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true

                }
            },

        ]
        // fade: true
    };
    const [reviews, setReviews] = useState([])
    const fetchReviews = async () => {
        try {
            let cc = await fetch(`${server}/mapReviews`)
            let pp = await cc.json()
            if(pp && pp.map && pp.map.result && pp.map.result.reviews){
                setReviews(pp.map.result.reviews)
            }

        } catch (error) {
            console.log("error", error)
        }
    }
    useEffect(() => {
        fetchReviews()
    }, [])
    // console.log("reviews", reviews)
    // const reviewData = [
    //     {
    //         rating: 3,
    //         name: "Nemo Nomad",
    //         details: `Lorem ipsum dolor sit amet consectetur adipisicing elit.
    //         Temporibus voluptatum nesciunt autem provident iusto
    //         ratione, sequi cumque aliquam sunt minus numquam earum
    //         vitae eaque commodi, harum, odit accusamus incidunt
    //         accusantium?`,
    //         image: "Images/LandingTestimonial/test1.jpg",
    //         time: "2 hours ago"
    //     },
    //     {
    //         rating: 4,
    //         name: "Tim Cook",
    //         details: `Lorem ipsum dolor sit amet consectetur adipisicing elit.
    //         Temporibus voluptatum nesciunt autem provident iusto
    //         ratione, sequi cumque aliquam sunt minus numquam earum
    //         vitae eaque commodi, harum, odit accusamus incidunt
    //         accusantium?`,
    //         image: "Images/LandingTestimonial/test2.jpg",
    //         time: "15 days ago"
    //     },
    //     {
    //         rating: 5,
    //         name: "Jason Bourne",
    //         details: `Lorem ipsum dolor sit amet consectetur adipisicing elit.
    //         Temporibus voluptatum nesciunt autem provident iusto
    //         ratione, sequi cumque aliquam sunt minus numquam earum
    //         vitae eaque commodi, harum, odit accusamus incidunt
    //         accusantium?`,
    //         image: "Images/LandingTestimonial/test3.jpg",
    //         time: "2 hours ago"
    //     },
    //     {
    //         rating: 1,
    //         name: "Ethan Hunt",
    //         details: `Lorem ipsum dolor sit amet consectetur adipisicing elit.
    //         Temporibus voluptatum nesciunt autem provident iusto
    //         ratione, sequi cumque aliquam sunt minus numquam earum
    //         vitae eaque commodi, harum, odit accusamus incidunt
    //         accusantium?`,
    //         image: "Images/LandingTestimonial/test2.jpg",
    //         time: "2 weeks ago"
    //     }
    // ]
    return (
        <div className={`section ${styles.outer}`}>
            <div className={`container ${styles.inner}`}>
                <div className="heading-container">
                    <h2 className="section-heading">{mySection && mySection.heading}</h2>
                    <p className="section-paragraph">
                        {mySection && mySection.paragraph}
                    </p>
                </div>

                <div className={styles.reviewsContainer}>
                    <Slider {...settings} className={`reviews-slick-container ${styles.reviewsSlick}`}>
                        {
                            reviews.map((rd: any, index) => {
                                return <div className={styles.reviewCard} key={index}>
                                    <div className={styles.reviewInner}>
                                        <img src={rd.profile_photo_url} alt="" />
                                        <div className={styles.details}>
                                            {rd.text}
                                        </div>
                                        <div className={styles.bottom}>
                                            <div className={styles.rating}>
                                                {/* <Rating style={{ maxWidth: 100 }} value={rd.rating} /> */}
                                            </div>
                                            <div className={styles.name}>
                                                {rd.author_name}
                                            </div>
                                            <div className={styles.time}>
                                                {rd.relative_time_description}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            })
                        }
                    </Slider>

                </div>
                <div className={styles.buttonContainer}>
                    <MyButton text='View More' />
                </div>
              
            </div>
        </div>
    )
}

export default LandingReviews