import LandingHero from 'Components/LandingHero/Index'
import styles from './styles.module.css'
// import { Helmet } from 'react-helmet'
// import { frontLink } from 'utils/index'
import LandingAbout from 'Components/LandingAbout/Index'
import LandingCertificates from 'Components/LandingCertificates/Index'
import LandingCourses from 'Components/LandingCourses/Index'
import LandingReviews from 'Components/LandingReviews/Index'
import LandingGallery from 'Components/LandingGallery/Index'
import LandingWhy from 'Components/LandingWhy/Index'
import LandingHM from 'Components/LandingHM/Index'
import LandingFind from 'Components/LandingFind/Index'



export default function Home() {
  return (
    <main className={styles.main}>
      {/* <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta property="og:url" content="https://nshm.edu.np" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Home - Maitricraft Nepal" />
        <meta property="og:description" content="Maitricraft Nepal produces different types of blogs from handicrafts, eco friendly lifestyle and working environments" />
        <meta property="og:image" content="/Assets/Images/LandingBlog/blog3.webp" />
        <title>Home - NSHM</title>
        <link rel="canonical" href={`${frontLink}`} />
      </Helmet> */}

      <LandingHero />
      <LandingAbout />
      <LandingHM />
      <LandingCourses />
      <LandingCertificates />
      <LandingWhy />
      <LandingGallery />
      <LandingReviews />
      <LandingFind />
    </main>
  )
}
