
// import { server } from 'utils/index'
import RequestForm from 'Components/RequestForm/Index'
import MyButton from '../MyButton/Index'
import styles from './styles.module.css'
import { useState } from 'react'
import MyModal from 'Components/MyModal/Index'

const CourseAction = ({ myCourse }: any) => {
  const [openRequest, setOpenAction] = useState<any>(false)

  return (
    <section className={`section ${styles.landingInvest}`} style={{
    }}>
      <div className={`container ${styles.inner}`}>
      <div className={styles.left}>
        <img src={`/Images/Courses/read.png`} alt="" />
        </div>
        <div className={styles.right}>

        <h2 className='section-heading'>Learn More</h2>
          <p className='section-paragraph'>
            You can contact us and inquire about the course further if you are interested. To contcat us, 
            you can use the offical contact information mentioned in the website or just fill out the form. we'll get
             back to you as soon as possible.
          </p>
          <div className={styles.brochureButton}>
            <MyButton text='Course Inquiry' onClick={() => setOpenAction(true)} />
            <MyModal state={openRequest} setState={setOpenAction}>
              <RequestForm
                setModal={setOpenAction}
                course={myCourse && myCourse.name ? myCourse.name : ""}
                />
            </MyModal>          
             </div>
        </div>
        
    </div>
    
    </section>



  )
}

export default CourseAction