import styles from './styles.module.css'
import { HiOutlineMail } from 'react-icons/hi';
import { BsPhone } from 'react-icons/bs';
import { CiLocationOn } from 'react-icons/ci';
import { BiLogoFacebook } from 'react-icons/bi';
import { AiOutlineWhatsApp, AiFillInstagram } from 'react-icons/ai';
import { FaViber } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { FeaturesContext } from 'Context/FeaturesContext/FeaturesContext';
import { server } from 'utils/index';
// import { server } from 'utils/index';



const Footer = () => {
  const {socials, contacts,sections} = useContext<any>(FeaturesContext)

  const [mySection, setMySection] = useState<any>()
  useEffect(() => {
      if (sections && sections.length > 0) {
          const cc = sections.find((sc: any) => sc.name.toLowerCase() == 'footer about')
          if (cc) {
              setMySection(cc)
          } else {
              setMySection(null)
          }
      }
  }, [sections, sections.length])
  const {pathname} = useLocation()
  const [isCourse, setIsCourse] = useState(false)
  useEffect(()=>{
if(pathname.split("/").includes('courses')){
  setIsCourse(true)
}
else{
  setIsCourse(false)
}
  },[pathname])
  return (
    <footer className={`${styles.footerContainer} ${isCourse? styles.courseFooter: ""}`}>
      <div className={`container ${styles.footerInner}`}>
        <div className={styles.headerTop}>
          <div className={styles.headerAbout}>
            <img

              src={`${server}/${mySection && mySection.image}`}
             
              alt="footer Logo"
            />
            <p className='section-paragraph'>
              {mySection && mySection.paragraph}
            </p>
          </div>
          <div className={styles.footerLinks}>
            <h2 className={styles.footerHeader}>Quick Links</h2>
            <div className={styles.linksBox}>
              <Link to="/" >
                Home
              </Link>
              <Link to="/about" >
                About
              </Link>
              <Link to="/courses" >
                Courses
              </Link>

              <Link to="/contact" >
                Contact
              </Link>

              
              <Link to="/certificates" >
                Certificates
              </Link>
            </div>
          </div>
          <div className={styles.footerContact}>
            <h2 className={styles.footerHeader}>Contact Us</h2>
            <ul>
              <li>
                <span className={styles.contactIcon}><HiOutlineMail /></span>
                <span className={styles.contactText}>{contacts.find((cc:any)=>cc.name == 'General Contact') && contacts.find((cc:any)=>cc.name == 'General Contact')?.email}</span>
              </li>
              <li>
                <span className={styles.contactIcon}><BsPhone /></span>
                <span className={styles.contactText}>{contacts.find((cc:any)=>cc.name == 'General Contact') && contacts.find((cc:any)=>cc.name == 'General Contact')?.phone}</span>
              </li>
              <li>
                <span className={styles.contactIcon}><CiLocationOn /></span>
                <span className={styles.contactText}>{contacts.find((cc:any)=>cc.name == 'General Contact') && contacts.find((cc:any)=>cc.name == 'General Contact')?.address}</span>
              </li>
              <li>
              <a href={`${socials.find((cc:any)=>cc.name == 'Main Social') && socials.find((cc:any)=>cc.name == 'Main Social')?.facebook}`} target='_blank'>
                  <BiLogoFacebook />
                </a>
                {/* <a href="https://www.linkedin.com/company/" target='_blank'>
                  <BiLogoLinkedin />
                </a> */}
              <a href={`${socials.find((cc:any)=>cc.name == 'Main Social') && socials.find((cc:any)=>cc.name == 'Main Social')?.instagram}`} target='_blank'>
                  <AiFillInstagram />
                </a>

                <a href={`https://wa.me/${socials.find((cc:any)=>cc.name == 'Main Social') && socials.find((cc:any)=>cc.name == 'Main Social')?.whatsapp}`} target='_blank'>
                  <AiOutlineWhatsApp />
                </a>
                <a href={`viber://chat?number=%2B${socials.find((cc:any)=>cc.name == 'Main Social') && socials.find((cc:any)=>cc.name == 'Main Social')?.viber}`} target='_blank'>
                  <FaViber />
                </a>
                {/* <a href="tel:+9779851328262" target='_blank'>
                  <BsPhone />
                </a> */}
              </li>
            </ul>
          </div>
         
        </div>
        <div className={styles.headerBottom}>
          © Nepal School Of Hotel Management  2023 ALL RIGHTS RESERVED | created and maintained by <a href="https://syronx.com" target='_blank'>SyronX Technology</a>
        </div>
      </div>
    </footer>
  )
}

export default Footer