import MyImage from 'Components/MyImage/Index'
import styles from './styles.module.css'
import { Link } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import { FeaturesContext } from 'Context/FeaturesContext/FeaturesContext'
import { server } from 'utils/index'

const LandingGallery = () => {
    const { sections } = useContext<any>(FeaturesContext)
    const [mySection, setMySection] = useState<any>()
    useEffect(() => {
        if (sections && sections.length > 0) {
            const cc = sections.find((sc: any) => sc.name.toLowerCase() == 'landing gallery')
            if (cc) {
                setMySection(cc)
            } else {
                setMySection(null)
            }
        }
    }, [sections, sections.length])
    return (
        <section className={styles.outer}>
            <div className={`container ${styles.inner}`}>
                <div className="heading-container">
                    <h2 className="section-heading">{mySection && mySection.heading}</h2>
                    <p className="section-paragraph">
                        {mySection && mySection.paragraph}
                    </p>
                </div>
                <div className={styles.galleryContainer}>
                    <Link className={styles.galleryGrid} to='/gallery'>
                        <div className={`${styles.gridItem} ${styles.gridItem1}`}>
                            <MyImage source={mySection && mySection.list && JSON.parse(mySection.list) && JSON.parse(mySection.list).data && `${server}/${JSON.parse(mySection.list).data[0].image}`} alt="Grid Image" className={styles.gridImage1} wrapperName={styles.wrapper}
                                style={{ height: "100%", width: "100%", objectFit: "cover" }}
                            />
                        </div>
                        <div className={`${styles.gridItem} ${styles.gridItem2}`}>
                            <MyImage
                                source={mySection && mySection.list && JSON.parse(mySection.list) && JSON.parse(mySection.list).data && `${server}/${JSON.parse(mySection.list).data[1].image}`} alt="Grid Image" className={styles.gridImage2} wrapperName={styles.wrapper}
                                style={{ height: "100%", width: "100%", objectFit: "cover" }}

                            />

                        </div>
                        <div className={`${styles.gridItem} ${styles.gridItem3}`}>
                            <MyImage
                                source={mySection && mySection.list && JSON.parse(mySection.list) && JSON.parse(mySection.list).data && `${server}/${JSON.parse(mySection.list).data[2].image}`} alt="Grid Image" className={styles.gridImage3} wrapperName={styles.wrapper}
                                style={{ height: "100%", width: "100%", objectFit: "cover" }}

                            />

                        </div>
                        <div className={`${styles.gridItem} ${styles.gridItem4}`}>
                            <MyImage
                                source={mySection && mySection.list && JSON.parse(mySection.list) && JSON.parse(mySection.list).data && `${server}/${JSON.parse(mySection.list).data[3].image}`} alt="Grid Image" className={styles.gridImage4} wrapperName={styles.wrapper}
                                style={{ height: "100%", width: "100%", objectFit: "cover" }}

                            />
                        </div>
                        <div className={`${styles.gridItem} ${styles.gridItem5}`}>
                            <MyImage
                                source={mySection && mySection.list && JSON.parse(mySection.list) && JSON.parse(mySection.list).data && `${server}/${JSON.parse(mySection.list).data[4].image}`} alt="Grid Image" className={styles.gridImage5} wrapperName={styles.wrapper}
                                style={{ height: "100%", width: "100%", objectFit: "cover" }}

                            />
                        </div>

                    </Link>
                </div>
            </div>
        </section>
    )
}

export default LandingGallery