import styles from './styles.module.css'
// import { server } from 'utils/index'
import { useEffect, useState } from 'react';
import { server } from 'utils/index';
import './lightSlider.css'
import Lightbox from "yet-another-react-lightbox";
import Inline from "yet-another-react-lightbox/plugins/inline";
const CourseInfo = ({ myCourse }: any) => {
    const [open, setOpen] = useState(false);
    const [index, setIndex] = useState(0);

    const [myImages, setMyImages] = useState<any>([])


    const toggleOpen = (state: boolean) => () => setOpen(state);

    const updateIndex = ({ index: current }: { index: number }) =>

        setIndex(current);

    useEffect(() => {
        if (myCourse && myCourse.images) {
            setIndex(0)
            let temp = JSON.parse(myCourse.images)
            let temp_arr = temp.map((tt: any) => ({ src: `${server}/${tt}` }))
            setMyImages(temp_arr)
        }
    }, [myCourse, myCourse.images])
    return (
        <section className={`section ${styles.productInfo}`}>
            <div className={`container ${styles.inner}`}>
                <div className={styles.left}>
                    <h2 className='section-heading'>{myCourse && myCourse.name}</h2>
                    <p className='section-paragraph'>
                        {myCourse && myCourse.info}
                    </p>

                </div>
                <div className={styles.right}>
                    {myImages.length > 0 && <>
                        <Lightbox
                            index={index}
                            slides={myImages}
                            plugins={[Inline]}
                            on={{
                                view: updateIndex,
                                click: toggleOpen(true),
                            }}
                            carousel={{
                                padding: 0,
                                spacing: 0,
                                imageFit: "cover",
                            }}
                            className={`lightSlider`}
                            inline={{
                                style: {
                                    width: "100%",
                                    maxWidth: "900px",
                                    aspectRatio: "3 / 2",
                                    margin: "0 auto",
                                },
                            }}
                        />
                        <Lightbox
                            open={open}
                            close={toggleOpen(false)}
                            index={index}
                            slides={myImages}
                            on={{ view: updateIndex }}
                            animation={{ fade: 0 }}
                            controller={{ closeOnPullDown: true, closeOnBackdropClick: true }}
                        />
                    </>}
                    {/* <MyImage
                        // src={myCourse ? JSON.parse(myCourse.images) && `${server}/${JSON.parse(myCourse.images)[0]}` : ""}
                        source={myCourse ? JSON.parse(myCourse.images) && `${JSON.parse(myCourse.images)[0]}` : ""}
                        alt='About Image'

                    /> */}
                </div>
            </div>
        </section>
    )
}

export default CourseInfo