import { ReactNode } from 'react'
import styles from './styles.module.css'
import { Modal } from 'antd';
type Props = {
    state: boolean,
    setState: (value: boolean) => void,
    children: ReactNode
}

const MyModal = ({ state, setState, children }: Props) => {


    return (
        <Modal
            title=""
            open={state}
            onOk={() => setState(false)}
            onCancel={() => setState(false)}
            okButtonProps={{ disabled: true }}
            cancelButtonProps={{ disabled: true }}
            cancelText={null}
            footer={false}
            className={`${styles.requestModal}`}
        >
            <div className={`${styles.requestModalInner} myScrollbar`}>
                {/* <div className={styles.cross} onClick={() => setState(false)}>
                    <RxCross1 />
                </div> */}

                {children}

            </div>
        </Modal>
    )
}

export default MyModal