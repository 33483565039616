"use client";
import React, { useState } from "react";
export const FeaturesContext = React.createContext({});

const FeaturesProvider = ({ children }: any) => {
    const [sections, setSections] = useState([]);
    const [courses, setCourses] = useState([]);
    const [testimonials, setTestimonials] = useState([]);
    const [team, setTeam] = useState([]);
    const [inquiry, setInquiry] = useState([]);
    const [blogs, setBlogs] = useState([]);
    const [sliders, setSliders] = useState([]);
    const [designs, setDesigns] = useState([]);
    const [category, setCategory] = useState([]);
    const [socials, setSocials] = useState([]);
    const [gallery, setGallery] = useState([]);

    const [contacts, setContacts] = useState([]);

    const providerValue = {
        sections,
        setSections,
        courses,
        setCourses,
        testimonials,
        setTestimonials,
        team,
        setTeam,
        inquiry,
        setInquiry,
        blogs,
        setBlogs,
        sliders,
        setSliders,
        designs,
        setDesigns,
        category,
        setCategory,
        socials,
        setSocials,
        contacts,
        setContacts,
        gallery,
setGallery
    };

    return (
        <FeaturesContext.Provider value={providerValue}>
            {children}
        </FeaturesContext.Provider>
    );
};

export default FeaturesProvider;
