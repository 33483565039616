import { GoDotFill } from "react-icons/go"
import styles from './styles.module.css'

const CourseFeatures = ({ myCourse }: any) => {
    return (

        <section className={`section ${styles.feature}`}>
            <div className={`container ${styles.inner}`}>
                <div className={`section-heading-container ${styles.sectionHeader}`}>
                    <h2 className='section-heading'>
                        Course Module
                    </h2>
                    <p className='section-paragraph'>
                       To learn about the course in detail, you have to visit the institution, 
                       because a couse is much more than few bullet points. However, we 
                       have presented an outline of our course:

                    </p>
                </div>
                <ul className={styles.featureList}>
                    {
                        myCourse && myCourse.features &&
                        JSON.parse(myCourse.features) && JSON.parse(myCourse.features).map((jp: any, index: number) => {
                            return <li key={index} className="section-paragraph">
                                <span><GoDotFill /></span>
                                {jp}
                            </li>
                        })}


                </ul>
            </div>
        </section>
    )
}

export default CourseFeatures