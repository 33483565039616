import { FeaturesContext } from 'Context/FeaturesContext/FeaturesContext'
import styles from './styles.module.css'
import { useContext, useEffect, useState } from 'react'

const LandingFind = () => {
  const { sections } = useContext<any>(FeaturesContext)
  const [mySection, setMySection] = useState<any>()
  useEffect(() => {
    if (sections && sections.length > 0) {
      const cc = sections.find((sc: any) => sc.name.toLowerCase() == 'landing find us title')
      if (cc) {
        setMySection(cc)
      } else {
        setMySection(null)
      }
    }
  }, [sections, sections.length])
  return (

    <section className={`section ${styles.section}`}>
      <div className={`container ${styles.inner}`}>
        <div className="heading-container">
          <h2 className="section-heading">{mySection && mySection.heading}</h2>
          <p className="section-paragraph">
            {mySection && mySection.paragraph}
          </p>
        </div>
        <div style={{ marginTop: "40px" }}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d56501.962048261164!2d85.22657744863278!3d27.736650500000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb196ab7dfd3ad%3A0x5cfd2489baac5ed6!2sNepal%20School%20Of%20Hotel%20Management!5e0!3m2!1sen!2snp!4v1696494151192!5m2!1sen!2snp"
            width="100%" height="400" style={{ border: "none" }} loading="lazy" ></iframe>

        </div>
      </div>
    </section>
  )
}

export default LandingFind