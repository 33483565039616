import styles from './styles.module.css'
import './hmslick.css'
import Slider from 'react-slick';
import { useContext, useEffect, useState } from 'react';
import { FeaturesContext } from 'Context/FeaturesContext/FeaturesContext';
import { server } from 'utils/index';

const LandingHM = () => {
    const { sections, sliders, category } = useContext<any>(FeaturesContext)
    const [mySection, setMySection] = useState<any>()
    useEffect(() => {
        if (sections && sections.length > 0) {
            const cc = sections.find((sc: any) => sc.name.toLowerCase() == 'landing about hotel management')
            if (cc) {
                setMySection(cc)
            } else {
                setMySection(null)
            }
        }
    }, [sections, sections.length])

    var settings = {
        dots: true,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 2000,
        centerMode: false,
        cssEase: 'linear',
        responsive: [

            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false

                }
            },

        ]
        // fade: true
    };
    console.log(sliders, category)
    return (
        <section className={`section ${styles.section}`}>
            <div className={`container ${styles.inner}`}>
                <div className="heading-container">
                    <h2 className="section-heading">{mySection && mySection.heading}</h2>
                    <p className="section-paragraph">
                        {mySection && mySection.paragraph}
                    </p>
                </div>
                <div className={styles.sliderOuter}>
                    <Slider {...settings} className={`hm-slick-container ${styles.hmSlick}`}>
                        {
                            category.length > 0 && sliders.length > 0
                            && category.find((ct: any) => ct.name.toLowerCase() == 'about hotel management slider')
                            && sliders.filter((ss: any) => ss.category == category.find((ct: any) => ct.name.toLowerCase() == 'about hotel management slider').id)
                            .map((tp: any, index: number) => {
                                return <div key={index} className={styles.item}>
                                    <img src={`${server}/${tp.image}`} alt="" />
                                    <h3>
                                        {tp.heading}

                                    </h3>
                                    <p className='section-paragraph'>
                                        {tp.paragraph}
                                    </p>
                                </div>
                            })
                        }
                    </Slider>

                </div>
            </div>

        </section>
    )
}

export default LandingHM