import { FeaturesContext } from 'Context/FeaturesContext/FeaturesContext'
import styles from './styles.module.css'
import { useContext, useEffect, useState } from 'react'
import { server } from 'utils/index'
import Mission from 'Components/Mission/Index'
import Vision from 'Components/Vision/Index'
import { BiLogoFacebook, BiLogoLinkedin } from 'react-icons/bi';
import MessageD from 'Components/MessageD/Index'
import MessageO from 'Components/MessageO/Index'
export const metadata = {
  title: 'About Us - Maitricraft Nepal',
  description: `Welcome to Maitri Craft Nepal - a haven of exquisite handcrafted products made from Lokta 
  paper and felt. We take great pride in offering unique and sustainable creations that 
  showcase the rich cultural heritage of Nepal.We believe in the art of crafting with love, 
  passion, and compassion.`,
  openGraph: {
    images: "/Assets/Images/About/about.webp",
  },
}

const About = () => {
  const { sections, team } = useContext<any>(FeaturesContext)
  const [mySection, setMySection] = useState<any>()
  const [teamHeading, setTeamHeading] = useState<any>()

  useEffect(() => {
    if (sections && sections.length > 0) {
      const cc = sections.find((sc: any) => sc.name.toLowerCase() == 'our story')
      if (cc) {
        setMySection(cc)
      } else {
        setMySection(null)
      }
    }
  }, [sections, sections.length])


  useEffect(() => {
    if (sections && sections.length > 0) {
      const cc = sections.find((sc: any) => sc.name.toLowerCase() == 'our team heading')
      if (cc) {
        setTeamHeading(cc)
      } else {
        setTeamHeading(null)
      }
    }
  }, [sections, sections.length])
  const teamIcon = [
    {
      icon: <BiLogoFacebook />,
      name: "facebook",
      color:"#316FF6"
    },
    {
      icon: <BiLogoLinkedin />,
      name: "linkedin",
      color:"#0A66C2"
    }
  ]
  return (
    <main className={styles.aboutPage}>
      <div className={`section ${styles.aboutHero}`} style={{ backgroundImage: `url(${server}/${mySection && mySection.image})` }}>

        <div className={`container ${styles.landingABoutInner}`} >
          <div className={styles.storyInner} >
            <h2 className="section-heading">{mySection && mySection.heading}</h2>
            <p className="section-paragraph">
              {mySection && mySection.paragraph}

            </p>
          </div>

        </div>

      </div>
      <section className={styles.mission}>
        <div className="container">
          <Vision />
          <Mission />
        </div>
      </section>
      <section className={`section ${styles.message}`}>
        <div className="container">
          <MessageD />
          <MessageO />
        </div>

      </section>

      <section className={`section ${styles.team}`}>
        <div className={`container ${styles.teamInner}`}>
          <div className={`heading-container ${styles.sectionHeader}`}>
            <h2 className='section-heading'>{teamHeading && teamHeading.heading}</h2>
            <p className='section-paragraph'>
              {teamHeading && teamHeading.paragraph}
            </p>
          </div>
          <div className={styles.teamContainer}>
            {team.sort((a: any, b: any) => a.rank - b.rank).map((td: any, index: number) => {
              return <div key={index} className={styles.teamCard}>
                {/* <div className={styles.teamSocial}>
                  <h3 className={styles.socialTitle}>
                    Find Me
                  </h3>
                  <div className={styles.socialBody}>
                    {
                      td.social && JSON.parse(td.social) && JSON.parse(td.social).map((tds: any, index1: number) => {
                        return <a className={styles.socialItem} href={tds.link} key={index1} target="_blank" style={{backgroundColor: teamIcon.find(tm => tm.name.toLowerCase() == tds.name.toLowerCase())?.color}}>
                          {teamIcon.find(tm => tm.name.toLowerCase() == tds.name.toLowerCase())?.icon}

                        </a>
                      })
                    }
                  </div>
                </div> */}
                <div className={styles.teamImage}>
                  <img
                    // src={`${server}/${td.image}`}
                    src={`${server}/${td.image}`}
                    alt='Team Image'
                    width={400}
                    height={400}
                  />
                </div>
                <div className={styles.teamDesc}>
                  <h3>{td.name}</h3>
                  <p>{td.role}</p>
                </div>

                <div className={styles.socialBody}>
                    {
                      td.social && JSON.parse(td.social) && JSON.parse(td.social).map((tds: any, index1: number) => {
                        return <a className={styles.socialItem} href={tds.link} key={index1} target="_blank" 
                        // style={{backgroundColor: teamIcon.find(tm => tm.name.toLowerCase() == tds.name.toLowerCase())?.color}}
                        >
                          {teamIcon.find(tm => tm.name.toLowerCase() == tds.name.toLowerCase())?.icon}

                        </a>
                      })
                    }
                  </div>
              </div>
            })}
          </div>
        </div>
      </section>

    </main>
  )
}

export default About