import { useContext, useEffect, useState } from 'react'
import styles from './styles.module.css'
import { FeaturesContext } from 'Context/FeaturesContext/FeaturesContext'
import { server } from 'utils/index'

const LandingWhy = () => {
    const { sections } = useContext<any>(FeaturesContext)
    const [mySection, setMySection] = useState<any>()
    useEffect(() => {
        if (sections && sections.length > 0) {
            const cc = sections.find((sc: any) => sc.name.toLowerCase() == 'why choose us')
            if (cc) {
                setMySection(cc)
            } else {
                setMySection(null)
            }
        }
    }, [sections, sections.length])
  
  return (
    <section className={styles.outer}>
        <div className={`container ${styles.inner}`}>
        <div className="heading-container">
                    <h2 className="section-heading">{mySection && mySection.heading}</h2>
                    <p className="section-paragraph">
{mySection && mySection.paragraph}
                    </p>
                </div>
        <div className={styles.bottom}>
                    <div className={styles.cardsContainer}>
                    {
                        mySection && mySection.list && JSON.parse(mySection.list) && JSON.parse(mySection.list).data.map((tp: any, index: number) => {
                            return <div className={styles.card} key={index}>
                                <img src={`${server}/${tp.image}`} alt="" />
                                <h3 className={styles.title}>{tp.heading}</h3>
                                <p className={`section-paragraph ${styles.paragraph}`}>{tp.paragraph}</p>
                            </div>
                        })
                    }
                    </div>
                </div>
        </div>
    </section>
  )
}

export default LandingWhy