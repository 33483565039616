import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

export default function GalleryComponent({open, setOpen, slides, index}:any) {
console.log(open, slides)
  return (
    <>

      <Lightbox
      index={index? index : 0}
        open={open}
        close={() => setOpen(false)}
        slides={slides}
      />
    </>
  );
}
