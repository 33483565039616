import styles from './styles.module.css';
import { Link } from 'react-router-dom';
type Props = {
    alternate?: boolean,
    text: string,
    onClick?: () => void,
    link?: boolean,
    href?: string,
    type?: 'submit' | 'reset' | 'button' | undefined;
    anchor?: boolean,
    download?:any
}

const MyButton = ({ text, onClick, alternate, link, href, type, anchor, download }: Props) => {
    return (
        link && href ?
            <Link to={href}
                className={`${alternate ? styles.alternateButton : styles.mainButton}`}
                onClick={() => onClick ? onClick() : null}
            >
                {text}
            </Link>
            :
            
            anchor && href && download ?
                <a href={href}
                download
                    className={`${alternate ? styles.alternateButton : styles.mainButton}`}
                >{text}</a>
                :
            anchor && href ?
                <a href={href}
                    className={`${alternate ? styles.alternateButton : styles.mainButton}`}
                >{text}</a>
                :
                <button
                    type={type ? type : "button"}
                    onClick={() => onClick ? onClick() : null}
                    className={`${alternate ? styles.alternateButton : styles.mainButton}`}>
                    {text}
                </button>

    )
}

export default MyButton