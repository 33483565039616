import MyModal from 'Components/MyModal/Index'
import styles from './style.module.css'
import MyButton from 'Components/MyButton/Index'

const CertificateModal = ({ success, payload, openAction, setOpenAction }: any) => {
    return (
        <MyModal state={openAction} setState={setOpenAction}>

            <div>
                <div className={styles.container}>
                    <h3 className={success? styles.successHeading : styles.failureHeading}>{success ? 'Certificate Found!' : 'Not Found!'}</h3>
                    <img src={success ? payload.image : '/Images/Certificate/failure.jpg'} alt="" />

                    {!success && <p className='section-paragraph'>Sorry! We couldn't find your certificate.
                        If you are certain of your training completion,
                        you can request for the certificate in the section below
                    </p>}
                    {success && <MyButton link={true} href={payload.image} download text='Download' />
                    }
                </div>

                <div>
                    {/* <MyButton /> */}
                </div>
            </div>

        </MyModal>
    )
}

export default CertificateModal