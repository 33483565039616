import { server } from "utils/index";

export const fetchGallery = async () => {
  try {
    const res2 = await fetch(`${server}/gallery/nshm`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${acessToken}`,
      },
    });
    const gallery = await res2.json();
    if (res2.status == 200 || res2.status == 201) {
      return gallery.data;
    }
    // if (res2.status == 401) {
    //   message.error("Your session has expired. Please reauthenticate again");
    //   return setLogout();
    // }
    return [];
  } catch (error) {

    return [];
  }
};


