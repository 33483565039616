import styles from './styles.module.css'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
const MyImage = ({ source, alt, style, className, wrapperName }: any) => {
    // const [isLoading, setIsLoading] = useState(false)
    return (
        <>
            <LazyLoadImage
                alt={alt}
                src={source}
                className={`${styles.imageLoad} ${className}`}
                style={style}
                wrapperClassName={`${wrapperName}`}
                effect="blur"
                />
        </>
    )
}

export default MyImage