import { useContext, useEffect, useState } from 'react'
import styles from './styles.module.css'
import { FeaturesContext } from 'Context/FeaturesContext/FeaturesContext'
import { server } from 'utils/index'

const MessageO = () => {
  const { sections } = useContext<any>(FeaturesContext)
  const [mySection, setMySection] = useState<any>()
  useEffect(() => {
    if (sections && sections.length > 0) {
      const cc = sections.find((sc: any) => sc.name.toLowerCase() == 'message from operational manager')
      if (cc) {
        setMySection(cc)
      } else {
        setMySection(null)
      }
    }
  }, [sections, sections.length])
  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <img src={mySection && `${server}/${mySection.image}`} alt="" />
      </div>
      <div className={styles.right}>
        <h2 className="section-heading">{mySection && mySection.heading}</h2>
        <p className="section-paragraph">
          {mySection && mySection.paragraph}
        </p>
        <span className={styles.quote}>- {mySection && mySection.subHeading}</span>
      </div>

    </div>)
}

export default MessageO