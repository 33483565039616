import { ReactNode, useEffect, useState } from 'react'
import Header from './Header/Index'
import Footer from './Footer/Index'
import styles from './styles.module.css'
import { useLocation } from 'react-router-dom'
type Props = {
  children: ReactNode
}

const PublicLayout = (props: Props) => {
  const { pathname } = useLocation()
  useEffect(() => {
    window.scroll({ top: 0, left: 0 })
  }, [pathname])
  const [isCourse, setIsCourse] = useState(false)
  useEffect(() => {
    if (pathname.split("/").includes('courses')) {
      setIsCourse(true)
    }
    else {
      setIsCourse(false)
    }
  }, [pathname])
  return (
    <div>
      <div className={`${isCourse ? styles.courseHeader : ""}`}>

        <Header />
      </div>
      <div className={styles.bodyContainer}>
        {props.children}
      </div>
      <div className={`${isCourse ? styles.courseFooter : ""}`}>

        <Footer />
      </div>

    </div>
  )
}

export default PublicLayout