import  {useState} from 'react'
import styles from './styles.module.css'
import {Link} from 'react-router-dom'
import { RxHamburgerMenu } from 'react-icons/rx'
import { GrFormClose } from 'react-icons/gr'

interface navLinks {
    name: string,
    route: string
}
const NaLinks: navLinks[] = [
    {
        name: "Home",
        route: "/"
    },
    {
        name: "About",
        route: "/about"
    },
    {
        name: "Gallery",
        route: "/gallery"
    },
    {
        name: "Courses",
        route: "/courses"
    },
    
    {
        name: "Certificates",
        route: "/certificates"
    },
    {
        name: "Contact",
        route: "/contact"
    }
]
const SmallNav = () => {
    const [triggerNav, setTriggerNav] = useState<boolean>(false)

    return (
        <div className={styles.navContainer}>
            <div className={styles.hamburger} onClick={()=> setTriggerNav(true)}>
                <RxHamburgerMenu />
            </div>
            <div className={styles.navBack} style={{opacity: triggerNav? '1' : "0", zIndex: triggerNav? "999" : "-999", height: triggerNav? "100%" : "0"}}>

            <div className={styles.navList}>

                {
                    NaLinks.map((nl, index) => {
                        return <Link key={index} to={nl.route} className={styles.navItem}  onClick={()=> setTriggerNav(false)}>{nl.name}</Link>
                    })
                }
            </div>
            <div className={styles.close} onClick={()=> setTriggerNav(false)}>
                <GrFormClose />
            </div>
            </div>
        </div>
    )
}

export default SmallNav