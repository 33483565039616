// import { useContext, useEffect, useState } from 'react'
import styles from './styles.module.css'
import { FaTruckFast, FaPercent } from 'react-icons/fa6'
import { GiTeamIdea } from 'react-icons/gi'
// import { FeaturesContext } from 'Context/FeaturesContext/FeaturesContext'


const CourseDuration = ({myCourse:{duration, level, placement}} : any) => {
  
//   const { sections } = useContext<any>(FeaturesContext)
//   const [myHelpline, setMyHelpline] = useState<any>()
//   const [myshipping, setMyShipping] = useState<any>()
//   const [myDiscount, setMyDiscount] = useState<any>()

//   useEffect(() => {
//       if (sections && sections.length > 0) {
//           const cc = sections.find((sc: any) => sc.name.toLowerCase() == 'helpline info')
//           if (cc) {
//             setMyHelpline(cc)
//           } else {
//             setMyHelpline(null)
//           }
//       }
//   }, [sections, sections.length])

  
//   useEffect(() => {
//     if (sections && sections.length > 0) {
//         const cc = sections.find((sc: any) => sc.name.toLowerCase() == 'discount info')
//         if (cc) {
//           setMyDiscount(cc)
//         } else {
//           setMyDiscount(null)
//         }
//     }
// }, [sections, sections.length])

// useEffect(() => {
//   if (sections && sections.length > 0) {
//       const cc = sections.find((sc: any) => sc.name.toLowerCase() == 'shipping info')
//       if (cc) {
//         setMyShipping(cc)
//       } else {
//         setMyShipping(null)
//       }
//   }
// }, [sections, sections.length])
  return (
    <section className={`section ${styles.footerTop}`}>
      <div className={`container ${styles.footerTopInner}`}>
        <div className={styles.footerBox}>
          <span className={styles.icon}>
            <FaTruckFast />
          </span>
          <div className={styles.info}>
          <span className={styles.title}>Course Duration</span>
            <span className={styles.text}>{duration}</span>

          </div>
        </div>
        <div className={styles.footerBox}>
          <span className={styles.icon}>
            <FaPercent />
          </span>
          <div className={styles.info}>
          <span className={styles.title}>Course Level</span>
          <span className={styles.text}>{level}</span>

          </div>
        </div>
        <div className={styles.footerBox}>
          <span className={styles.icon}>
            <GiTeamIdea />
          </span>
          <div className={styles.info}>
            <span className={styles.title}>Placement & Certificates</span>
            <span className={styles.text}>{placement}</span>

          </div>
        </div>
      </div>
    </section>
  )
}

export default CourseDuration