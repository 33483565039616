import { useState, useEffect, useRef, useContext } from 'react'
import { BsSearch } from 'react-icons/bs'
import styles from './styles.module.css'
import { RxCross1 } from 'react-icons/rx'
import {Link} from 'react-router-dom'
// import { productData } from 'public/Data/productData'
import { FeaturesContext } from 'Context/FeaturesContext/FeaturesContext'


type Props = {
    state: boolean,
    setState: (value: boolean) => void,
}

const SearchBlanket = ({ state, setState }: Props) => {
    const [searchTerms, setSearchTerms] = useState('')
    const {courses} = useContext<any>(FeaturesContext)
    const search = useRef<any>()
    useEffect(() => {
        if (search && search.current) {
            search.current.focus()
        }
    }, [state, search.current])
    return (
        <div className={styles.searchOuter} style={{ opacity: state ? "1" : "0", zIndex: state ? "500" : "-5", width: state ? "100vw" : "0", height: state ? "100vh" : "0" }}>
            <div className={styles.cross} onClick={() => setState(false)}>
                <RxCross1 />
            </div>

            <div className={styles.searchBox}>
                <input type="search"
                    ref={search}
                    autoFocus={true}
                    placeholder='Search courses...'
                    value={searchTerms}
                    onChange={(e) => setSearchTerms(e.target.value)}
                />
                <div className={styles.searchIcon}>
                    <BsSearch />
                </div>
            </div>
            <div className={styles.searchInner}>
                {
                    courses.filter((pd:any) => searchTerms && pd.name.toLowerCase().includes(searchTerms.toLowerCase())).map((pd:any, index:number) => {
                        return <Link className={styles.searchItem} key={index} onClick={() => setState(false)}
                            to={`/courses/${pd.name.toLowerCase().replaceAll(" ", "-").replaceAll(",", "-")}`}>{pd.name}</Link>
                    })
                }
            </div>
        </div>
    )
}

export default SearchBlanket