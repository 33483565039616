import styles from './styles.module.css'
import MyButton from 'Components/MyButton/Index'
import { server } from 'utils/index'

import { useForm, SubmitHandler } from "react-hook-form"

import { message } from 'antd'
import CertificateModal from 'Components/CertificateModal/Index'
import { useState } from 'react'
type Inputs = {
    name: string,
    dob: string
}
const CertificateForm = () => {
    const [openAction, setOpenAction] = useState<boolean>(false)
    const [payload, setPayload] = useState<any>()
    const [success, setSuccess] = useState<boolean>(false)

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<Inputs>()

    const onSubmit: SubmitHandler<Inputs> = async ({ name, dob }) => {
        // setSuccess(false)
        // setPayload({image:"/Images/Certificate/failure.jpg"})
        // setOpenAction(true)
        // return message.success("Testing success")
        try {
            const res = await fetch(`${server}/certificate/${name}/${dob}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                }
            });
            const data = await res.json();
            if (res.status == 200 || res.status == 201) {
                reset()
                setSuccess(true)
                setPayload({ image: `${server}/${data.data.certificate}` })
                setOpenAction(true)
                return message.success(data.message)
            }
            setSuccess(false)
            setPayload({ image: "/Images/Certificate/failure.jpg" })
            setOpenAction(true)
            return message.error(data.message)
        } catch (error) {
            console.log("error", error)
            message.error(`Searching certificate failed!`);
        }

    }
    return (
        <form action="post" onSubmit={handleSubmit(onSubmit)} className={styles.formContainer}>
            <div className={styles.inputContainer}>
                <label htmlFor="name">Name</label>
                <span className={styles.required}>*</span>
                <input type="text" placeholder='Enter Your Name' {...register("name", { required: true })} />
                {errors.name && <span className={styles.errorText}>Name required</span>}
            </div>
            <div className={styles.inputContainer}>
                <label htmlFor="date">Date of birth</label>
                <span className={styles.required}>*</span>
                <input type="date" placeholder='Enter Your DOB' {...register("dob", { required: true })} />
                {errors.dob && <span className={styles.errorText}>Date Of Birth required</span>}
            </div>

            <div className={styles.inputContainer} style={{}}>
                <MyButton text='Find' type="submit" />
            </div>
            <CertificateModal openAction={openAction} setOpenAction={setOpenAction} success={success} payload={payload} />
        </form>)
}

export default CertificateForm