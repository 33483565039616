import { FeaturesContext } from 'Context/FeaturesContext/FeaturesContext'
import styles from './styles.module.css'
import { useContext, useEffect, useState } from 'react'
import { server } from 'utils/index'
import MyImage from 'Components/MyImage/Index'
import GalleryComponent from 'Components/GalleryComponent/Index'
export const metadata = {
  title: 'Investments - Maitricraft Nepal',
  description: "Feel free to contact us for any queries, project collaborations, or to discuss how we can help you achieve your goals. Our team is excited to hear from you and explore the endless possibilities of working together. Let's turn your visions into reality!",
  openGraph: {
    images: "/Images/Contact/contact.png",
  },
}


const Gallery = () => {
  const { sections, gallery } = useContext<any>(FeaturesContext)
  const [myGallery, setMyGallery] = useState<any>([])
  const [index, setIndex] = useState(-1)

  const [mySection, setMySection] = useState<any>()
  useEffect(() => {
    let temp = gallery.map((gd:any) => ({ src: `${server}/${gd.image}`, width: 400, height: 400 }))
    setMyGallery([...temp])
  }, [gallery, gallery.length])
  useEffect(() => {
    if (sections && sections.length > 0) {
      const cc = sections.find((sc: any) => sc.name.toLowerCase() == 'gallery hero')
      if (cc) {
        setMySection(cc)
      } else {
        setMySection(null)
      }
    }
  }, [sections, sections.length])

  console.log("gallery", gallery)
  return (
    <main className={styles.investmentPage}>
      <section className={styles.investmentHero}>
        <div className={`container ${styles.investmentHeroInner}`}>
          <div className={styles.left}>
            <h2 className='section-heading'>{mySection && mySection.heading}
            </h2>
            <p className='section-paragraph'>
              {mySection && mySection.paragraph}

            </p>
          </div>
          <div className={styles.right}>

            <MyImage
              source={mySection && mySection.list && JSON.parse(mySection.list) && JSON.parse(mySection.list).data && `${server}/${JSON.parse(mySection.list).data[0].image}`}
              alt='Gallery Image'
              wrapperName={styles.image1}
              style={{
                height: "100%",
                width: "100%",
                objectFit: "cover"
              }}
            />
            <MyImage
              source={mySection && mySection.list && JSON.parse(mySection.list) && JSON.parse(mySection.list).data && `${server}/${JSON.parse(mySection.list).data[1].image}`}
              alt='Gallery Image'
              wrapperName={styles.image2}
              style={{
                height: "100%",
                width: "100%",
                objectFit: "cover"
              }}
            />
            <MyImage
              source={mySection && mySection.list && JSON.parse(mySection.list) && JSON.parse(mySection.list).data && `${server}/${JSON.parse(mySection.list).data[2].image}`}
              alt='Gallery Image'
              wrapperName={styles.image3}
              style={{
                height: "100%",
                width: "100%",
                objectFit: "cover"
              }}
            />
          </div>

        </div>

      </section>

      <section className={styles.galleryOuter}>
        <div className={`container ${styles.galleryInner}`}>
          <div className="heading-container">
            <h2 className='section-heading'>
              Browse Through Our Gallery
            </h2>
          </div>
          <GalleryComponent slides={myGallery} open={index >= 0} setOpen={() => setIndex(-1)} index={index} />
          {/* <PhotoAlbum layout="rows" photos={myGallery}
            // targetRowHeight={150}
            onClick={({ index: current }) => setIndex(current)} /> */}
          {
            myGallery.map((gd: any, index: number) => {
              return <div onClick={() => setIndex(index)} style={{ cursor: "pointer" }}>
                <MyImage source={gd.src} key={index} />

              </div>
            })
          }
        </div>
      </section>

    </main>
  )
}

export default Gallery