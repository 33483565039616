import MyButton from 'Components/MyButton/Index'
import styles from './styles.module.css'
import { FeaturesContext } from 'Context/FeaturesContext/FeaturesContext'
import { useContext, useEffect, useState } from 'react'
import { server } from 'utils/index'

const LandingCertificates = ({ }: any) => {
    const { sections } = useContext<any>(FeaturesContext)
    const [mySection, setMySection] = useState<any>()
    useEffect(() => {
      if (sections && sections.length > 0) {
        const cc = sections.find((sc: any) => sc.name.toLowerCase() == 'landing certificates')
        if (cc) {
          setMySection(cc)
        } else {
          setMySection(null)
        }
      }
    }, [sections, sections.length])
    return (
        <div className={`section ${styles.outer}`} style={{backgroundImage:`url(${server}/${mySection && mySection.image})`}}>
            <div className={styles.inner}>
                {/* <h3 className="section-sub-heading">You will be forever embded in us</h3> */}
                <h2 className="section-heading">{mySection && mySection.heading}</h2>
                <p className="section-paragraph">
                    {mySection && mySection.paragraph}
                </p>
                <div className={styles.button}>
                <MyButton text='Learn More'  link={true} href='/certificates'/>
                </div>
            </div>
        </div>
    )
}

export default LandingCertificates