import { useContext, useEffect, useState } from 'react'
import styles from './styles.module.css';
import Nav from 'Components/Nav/Index';
import SmallNav from 'Components/SmallNav/Index';
import { Link, useLocation } from 'react-router-dom';
import { BiLogoFacebook } from 'react-icons/bi';
import { BsSearch } from 'react-icons/bs';
import { AiOutlineWhatsApp, AiFillInstagram } from 'react-icons/ai';
import { FaViber } from 'react-icons/fa';
import MyButton from 'Components/MyButton/Index';
import SearchBlanket from 'Components/SearchBlanket/Index';
import { FeaturesContext } from 'Context/FeaturesContext/FeaturesContext';

const Header = () => {
  const [openSearch, setOpenSearch] = useState(false)
  const { socials, contacts } = useContext<any>(FeaturesContext)
  const [showBig, setShowBig] = useState<boolean>(false)
  const startScroll = () => {

    if (window.scrollY > 100) {
      setShowBig(true)
    }
    else {
      setShowBig(false)
    }

  }
  useEffect(() => {
    window.addEventListener('scroll', startScroll)

    return () => window.removeEventListener('scroll', startScroll)
  }, [])
  const { pathname } = useLocation()
  const [isCourse, setIsCourse] = useState(false)
  useEffect(() => {
    if (pathname.split("/").includes('courses')) {
      setIsCourse(true)
    }
    else {
      setIsCourse(false)
    }
  }, [pathname])
  return (
    <header className={`${styles.headerContainer} ${isCourse ? styles.courseHeader : ""}`}>
      <div className={styles.headerTop} >
        <div className={`container ${styles.headerTopInner}`}>

          <div className={styles.left}>
            <span className={styles.leftItem}>
              <span className={styles.title}>Phone :</span>
              <span className={styles.info}>{contacts.find((cc: any) => cc.name == 'General Contact') && contacts.find((cc: any) => cc.name == 'General Contact')?.phone?.split(",")[0]}</span>
            </span>
            <span className={styles.leftItem}>
              <span className={styles.title}>Email :</span>
              <span className={styles.info}>{contacts.find((cc: any) => cc.name == 'General Contact') && contacts.find((cc: any) => cc.name == 'General Contact').email}</span>
            </span>
          </div>
          <div className={styles.right}>
            <a href={`${socials.find((cc: any) => cc.name == 'Main Social') && socials.find((cc: any) => cc.name == 'Main Social')?.facebook}`} target='_blank'>
              <BiLogoFacebook />
            </a>
            <a href={`${socials.find((cc: any) => cc.name == 'Main Social') && socials.find((cc: any) => cc.name == 'Main Social')?.instagram}`} target='_blank'>
              <AiFillInstagram />
            </a>
            <a href={`https://wa.me/${socials.find((cc: any) => cc.name == 'Main Social') && socials.find((cc: any) => cc.name == 'Main Social')?.whatsapp}`} target='_blank'>
              <AiOutlineWhatsApp />
            </a>
            <a href={`viber://chat?number=%2B${socials.find((cc: any) => cc.name == 'Main Social') && socials.find((cc: any) => cc.name == 'Main Social')?.viber}`} target='_blank'>
              <FaViber />
            </a>
          </div>
        </div>
      </div>
      <div className={`${styles.headerBottom} ${showBig ? styles.showBig : ''}`}>
        <div className={`container ${styles.headerBottomInner}`}>
          <Link className={styles.headerLeft} to='/' >
            <img
              src='/Images/Logo/logo.png'
              alt='SyronX Logo'
              className={styles.logo}
              width={400}
              height={400}
            />
          </Link>
          <div className={styles.headerRight}>
            <Nav />
            <SmallNav />
            <span className={styles.line}></span>
            <div className={styles.donateContainer}>
              <div className={styles.search} onClick={() => setOpenSearch(true)}>
                <BsSearch />
              </div>
              <div className={styles.searchButton}>
                <MyButton text='Certificates' link={true} href='/certificates' />
              </div>
            </div>
          </div>

        </div>
      </div>
      <SearchBlanket state={openSearch} setState={setOpenSearch} />

    </header>
  )
}

export default Header