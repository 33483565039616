import { ReactNode, useEffect, useState } from 'react'
import styles from './styles.module.css'
type Props = {
    children: ReactNode
}

function Preloader({ children }: Props) {
    const [showLoader, setShowLoader] = useState(true)
    useEffect(() => {
        setTimeout(() => {
            setShowLoader(false)
        }, 2800)
    }, [])
    return (
        <div className={styles.preloaderContainer}>
            <div className={`${styles.preloader} ${showLoader ? styles.loaderOn : styles.loaderOff}`}>
                <div className={styles.logo}>
                    <img src="/Images/Logo/logo.png" alt="" />
                </div>
            </div>
            {children}
        </div>
    )
}

export default Preloader