import styles from './styles.module.css'
import MyButton from 'Components/MyButton/Index'
import { FeaturesContext } from 'Context/FeaturesContext/FeaturesContext'
import { useContext, useEffect, useState } from 'react'
import { server } from 'utils/index'
import { useForm, SubmitHandler } from "react-hook-form"
import { message } from 'antd'

export const metadata = {
  title: 'Contact Us - Maitricraft Nepal',
  description: "Feel free to contact us for any queries, project collaborations, or to discuss how we can help you achieve your goals. Our team is excited to hear from you and explore the endless possibilities of working together. Let's turn your visions into reality!",
  openGraph: {
    images: "/Images/Contact/contact.png",
  },
}
type Inputs = {
  name: string,
  email: string,
  address: string,
  imessage: string,
  phone: number
}

const Contact = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Inputs>()
  const { sections, category } = useContext<any>(FeaturesContext)
  const [mySection, setMySection] = useState<any>()
  useEffect(() => {
    if (sections && sections.length > 0) {
      const cc = sections.find((sc: any) => sc.name.toLowerCase() == 'contact hero')
      if (cc) {
        setMySection(cc)
      } else {
        setMySection(null)
      }
    }
  }, [sections, sections.length])

  const onSubmit: SubmitHandler<Inputs> = async ({ name, email, phone, address, imessage }) => {
    console.log(category.find((cat: any) => cat.type == 'inquiry' && cat.name == 'General Inquiry').id)
    try {
      const res = await fetch(`${server}/inquiry`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          email,
          sendWhatsapp: true,
          phone,
          address,
          message: imessage,
          category: category.find((cat: any) => cat.type == 'inquiry' && cat.name == 'General Inquiry').id,
          project: "nshm"
        }),
      });
      const data = await res.json();
      if (res.status == 200 || res.status == 201) {
        reset()
        return message.success('We have received your inquiry! Our team will reach you as soon as possible!');
      }
      console.log("error", data)
      return message.error(data.message);
    } catch (error) {
      console.log("error", error)
      message.error(`Inquiry request failed! Please try again!!`);
    }

  }

  return (
    <div className={styles.contactPage}>
      <section className={styles.contactHero}>
        <div className={`container ${styles.contactHeroInner}`}>
          <div className={styles.left}>
            <h2 className='section-heading'>{mySection && mySection.heading}</h2>
            <p className='section-paragraph'>
              {mySection && mySection.paragraph}
              {/* Feel free to contact us for any queries, project collaborations, or to discuss how we can help you
              achieve your goals. Our team is excited to hear from you and explore the endless possibilities of
              working together. Let&apos;s turn your visions into reality! */}
            </p>
          </div>
          <div className={styles.right}>

            <img
              src={`${server}/${mySection && mySection.image}`}
              alt='Contact Image'
            />
          </div>

        </div>

      </section>

      <section className={styles.contactForm}>
        <div className={`container ${styles.contactFormInner}`}>
          <div className={styles.left}>

            <img
              src='/Images/Contact/bird.jpg'
              alt='Contact Image'
            />
          </div>
          <div className={styles.right}>
            <h2 className='section-heading'>Message Us</h2>
            <div className={styles.contactFormContainer}>
              <form action="post" onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.inputContainer}>
                  <label htmlFor="name">Name</label>
                  <span className={styles.required}>*</span>
                  <input type="text" placeholder='Enter Your Name' {...register("name", { required: true })} />
                  {errors.name && <span className={styles.errorText}>Name required</span>}

                </div>
                <div className={styles.inputContainer}>
                  <label htmlFor="email">Email</label>
                  <span className={styles.required}>*</span>
                  <input type="text" placeholder='Enter Your Email' {...register("email", { required: true })} />
                  {errors.email && <span className={styles.errorText}>Email required</span>}

                </div>
                <div className={styles.inputContainer}>
                  <label htmlFor="phone">Phone</label>
                  <span className={styles.required}>*</span>
                  <input type="number" placeholder='Enter Your Phone' {...register("phone", { required: true })} />
                  {errors.phone && <span className={styles.errorText}>Phone required</span>}

                </div>
                <div className={styles.inputContainer}>
                  <label htmlFor="address">Address</label>
                  <span className={styles.required}>*</span>
                  <input type="text" placeholder='Enter Your Address' {...register("address", { required: true })} />
                  {errors.address && <span className={styles.errorText}>Address required</span>}

                </div>
                <div className={styles.inputContainer}>
                  <label htmlFor="message">Message</label>
                  <span className={styles.required}>*</span>
                  <textarea placeholder='Enter Your Message' {...register("imessage", { required: true })} />
                  {errors.imessage && <span className={styles.errorText}>Message required</span>}

                </div>
                <div className={styles.inputContainer}>
                  <MyButton text='Send' type="submit" />
                </div>
              </form>
            </div>

          </div>
        </div>

      </section>
      <section className={styles.map}>
        <div className="">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d56501.962048261164!2d85.22657744863278!3d27.736650500000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb196ab7dfd3ad%3A0x5cfd2489baac5ed6!2sNepal%20School%20Of%20Hotel%20Management!5e0!3m2!1sen!2snp!4v1696494151192!5m2!1sen!2snp"
            width="100%" height="400" style={{ border: "none" }} loading="lazy" ></iframe>

        </div>
      </section>

    </div>
  )
}

export default Contact