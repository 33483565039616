'use client';
import React, { useContext, useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import {
  createBrowserRouter,
  RouterProvider,
  Outlet
} from "react-router-dom";
import Home from 'Pages/Home/Index';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PublicLayout from 'Layout/PublicLayout/Index';
import "@fontsource/jost/300.css";
import "@fontsource/jost/400.css";
import "@fontsource/jost/500.css";
import "@fontsource/jost/700.css";
import "@fontsource/jost/900.css";
import "@fontsource/ubuntu/300.css";
import "@fontsource/ubuntu/400.css";
import "@fontsource/ubuntu/500.css";
import "@fontsource/ubuntu/700.css";
import "@fontsource/nobile/400.css";
import "@fontsource/nobile/500.css";
import "@fontsource/nobile/700.css";

import FeaturesProvider, { FeaturesContext } from 'Context/FeaturesContext/FeaturesContext';
import { fetchSection } from 'utils/api-calls/sectionCalls';
import { fetchDesign } from "utils/api-calls/designCalls";
import { fetchCategory } from "utils/api-calls/categoryCalls";
import { fetchTeam } from "utils/api-calls/teamCalls";
import { fetchInquiry } from "utils/api-calls/inquiryCalls";
import { fetchSlider } from "utils/api-calls/sliderCalls";
import { fetchContact } from 'utils/api-calls/contactCalls';
import { fetchSocial } from 'utils/api-calls/socialCalls';
import Preloader from 'Components/Preloader/Index';
import About from 'Pages/About/Index';
import Courses from 'Pages/Courses/Index';
import Contact from 'Pages/Contact/Index';
import Certificate from 'Pages/Certificate/Index';
import Gallery from 'Pages/Gallery/Index';
import CourseInner from 'Pages/CourseInner/Index';
import { fetchCourse } from 'utils/api-calls/courseCalls';
import { fetchGallery } from 'utils/api-calls/galleryCalls';
import CourseLayout from 'Layout/CourseLayout/Index';
// import ReactGA from "react-ga4";
const router = createBrowserRouter([
  {
    path: "/",
    element: <PublicLayout>
      <Outlet />
    </PublicLayout>,
    children: [
      {
        path: "/",
        element: <Home />
      },
      {
        path: "/about",
        element: <About />
      },
      {
        path: "/gallery",
        element: <Gallery />
      },
      {
        path: "/courses",
        element:
          <CourseLayout>
            <Courses />
          </CourseLayout>
      },
      {
        path: "/courses/:name",
        element:
          <CourseLayout>
            <CourseInner />
          </CourseLayout>
      },
      {
        path: "/contact",
        element: <Contact />
      },
      {
        path: "/certificates",
        element: <Certificate />
      },

    ],
  },
]);
const Index = () => {
  const {
    setSections,
    setCourses,
    setGallery,
    setTeam,
    setInquiry,
    setSliders,
    setDesigns,
    setCategory,
    setSocials,
    setContacts
  } = useContext<any>(FeaturesContext);
  // useEffect(()=>{
  //   ReactGA.initialize("G-9QP2RCQVHJ");
  // },[])
  const fetchSectionCall = async () => {
    let sectionF = await fetchSection();
    setSections(sectionF);
  }
  useEffect(() => {
    fetchSectionCall()

  }, [])

  const fetchCourseCall = async () => {
    let productF = await fetchCourse();
    setCourses(productF);
  }
  useEffect(() => {
    fetchCourseCall()
  }, [])

  const fetchGalleryCall = async () => {
    let testimonialf = await fetchGallery();
    setGallery(testimonialf);
  }
  useEffect(() => {
    fetchGalleryCall()
  }, [])

  const fetchDesignCall = async () => {
    let designf = await fetchDesign();
    setDesigns(designf);
  }
  useEffect(() => {
    fetchDesignCall()
  }, [])
  const fetchCategoryCall = async () => {
    let categoryf = await fetchCategory();
    setCategory(categoryf);
  }
  useEffect(() => {
    fetchCategoryCall()
  }, [])
  const fetchTeamCall = async () => {
    let teamf = await fetchTeam();
    setTeam(teamf);
  }
  useEffect(() => {
    fetchTeamCall()
  }, [])
  const fetchInquiryCall = async () => {
    let inquiryf = await fetchInquiry();
    setInquiry(inquiryf);
  }
  useEffect(() => {
    fetchInquiryCall()
  }, [])
  const fetchSliderCall = async () => {
    let sliderf = await fetchSlider();
    setSliders(sliderf);
  }
  useEffect(() => {
    fetchSliderCall()
  }, [])

  const fetchSocialCall = async () => {
    let socialf = await fetchSocial();
    setSocials(socialf);
  }
  useEffect(() => {
    fetchSocialCall()

  }, [])
  const fetchContactCall = async () => {
    let contactf = await fetchContact();
    setContacts(contactf);
  }
  useEffect(() => {
    fetchContactCall()

  }, [])

  return (
    <Preloader>
      <RouterProvider router={router} />
    </Preloader>
  )
}
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <FeaturesProvider>
    <Index />
    </FeaturesProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
