import {useState, useEffect} from 'react'
import styles from './styles.module.css'
import { useLocation, Link } from 'react-router-dom'
interface navLinks {
    name: string,
    route: string
}
const NaLinks: navLinks[] = [
    {
        name: "Home",
        route: "/"
    },
    {
        name: "About",
        route: "/about"
    },
    {
        name: "Gallery",
        route: "/gallery"
    },
    {
        name: "Courses",
        route: "/courses"
    },
    {
        name: "Contact",
        route: "/contact"
    }
]
const Nav = () => {
    const [selected, setSelected] = useState('')
    const {pathname} = useLocation()
    useEffect(() => {
     setSelected(pathname)
    
      return () => {
        
      }
    }, [pathname])
    
    return (
        <div className={styles.navContainer}>
            {
                NaLinks.map((nl, index) => {
                    return <Link key={index} to={nl.route} className={`${styles.navItem} ${selected==nl.route? styles.selected:""}`}>{nl.name}</Link>
                })
            }
        </div>
    )
}

export default Nav