import CourseCard from 'Components/CourseCard/Index'
import styles from './styles.module.css'
import Slider from 'react-slick';
import './coursesslick.css'
import { FeaturesContext } from 'Context/FeaturesContext/FeaturesContext';
import { useContext, useEffect, useState } from 'react';
const LandingCourses = ({ }: any) => {
    const { sections, courses } = useContext<any>(FeaturesContext)
    const [mySection, setMySection] = useState<any>()
    useEffect(() => {
      if (sections && sections.length > 0) {
        const cc = sections.find((sc: any) => sc.name.toLowerCase() == 'landing courses title')
        if (cc) {
          setMySection(cc)
        } else {
          setMySection(null)
        }
      }
    }, [sections, sections.length])
    var settings = {
        dots: false,
        arrows: true,
        infinite: true,      
        slidesToShow: 3,
        slidesToScroll: 1,
        speed: 500,
        autoplay: false,
        autoplaySpeed: 1500,
        responsive: [
            
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            
        ]
        // fade: true
    };
    return (
        <main className={`section ${styles.outer}`}>
            <div className={`container ${styles.inner}`}>
                <div className="heading-container">
                <h2 className="section-heading">{mySection && mySection.heading}</h2>
                <p className="section-paragraph">
                    {mySection && mySection.paragraph}
                </p>
                </div>
                
                <Slider {...settings} className={`${styles.coursesContainer} courses-slick-container`}>
                        {
                            courses.map((cd: any, index: number) => {
                                return <CourseCard item={cd} key={index} />

                            })
                        }
                    </Slider>
            </div>
        </main>
    )
}

export default LandingCourses