import styles from './styles.module.css'
import CourseCard from 'Components/CourseCard/Index'
import { useContext, useEffect, useState } from 'react'
import { FeaturesContext } from 'Context/FeaturesContext/FeaturesContext'

const Courses = () => {
  const { sections, courses } = useContext<any>(FeaturesContext)
  const [mySection, setMySection] = useState<any>()
  useEffect(() => {
    if (sections && sections.length > 0) {
      const cc = sections.find((sc: any) => sc.name.toLowerCase() == 'landing courses title')
      if (cc) {
        setMySection(cc)
      } else {
        setMySection(null)
      }
    }
  }, [sections, sections.length])
  return (
    <main>
      <section className={styles.outer}>
        <div className={`container ${styles.inner}`}>
          <div className="heading-container">
            <h2 className="section-heading">{mySection && mySection.heading}</h2>
            <p className="section-paragraph">
              {mySection && mySection.paragraph}
            </p>
          </div>
          <div className={styles.coursesContainer}>
            {
              courses.map((cd: any, index: number) => {
                return <CourseCard item={cd} key={index} />

              })
            }
          </div>

        </div>
      </section>
    </main>
  )
}

export default Courses