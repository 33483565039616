import { useForm, SubmitHandler } from "react-hook-form"
import MyButton from '../MyButton/Index'
import styles from './styles.module.css'
import { FeaturesContext } from 'Context/FeaturesContext/FeaturesContext'
import { useContext } from 'react'
import { server } from "utils/index"
import { message } from "antd"
import MyImage from "Components/MyImage/Index"
type Props = {
  course: string,
  setModal: any
}
type Inputs = {
  name: string,
  address: string,
  phone: number,
  email:string,
  imessage: string
}
const RequestForm = ({ course, setModal }: Props) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Inputs>()
  const {  category } = useContext<any>(FeaturesContext)


  const onSubmit: SubmitHandler<Inputs> = async ({ name, phone, address,email, imessage }) => {

    try {
      const res = await fetch(`${server}/inquiry`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          phone,
          sendWhatsapp:true,
          address,
          email,
          message: imessage,
          category: category.find((cat: any) => cat.type == 'inquiry' && cat.name == 'Course Inquiry').id,
          course: course,
          project: "nshm"
        }),
      });
      const data = await res.json();
      if (res.status == 200 || res.status == 201) {
        reset()
        setModal(false)
        return message.success('We have received your inquiry! Our team will reach you as soon as possible!');
      }
      return message.error(data.message);
    } catch (error) {
      console.log("error", error)
      message.error(`Sending product inquiry failed!`);
    }

  }
  return (
    <div className={styles.requestFormOuter}>
      <MyImage source="/Images/Request/request.jpg" alt="Request Image" className={styles.requestImage} />
      <div className={`section-header-container ${styles.header}`}>
        <h2 className='section-header'>Course Inquiry</h2>
        <h3 className='section-sub-header'>We respond to your query quickly</h3>

      </div>
      <form action="post" className={styles.requestFormContainer} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.inputContainer}>
           <label htmlFor="course">Course Name</label>
          <span className={styles.required}>*</span>
          <input value={course} readOnly={true} name='course' type="text" placeholder='Enter Course Name' />
        </div>
        
        <div className={styles.inputContainer}>
          <label htmlFor="name">Name</label>
          <span className={styles.required}>*</span>
          <input type="text" placeholder='Enter Your Name' {...register("name", { required: true })} />
          {errors.name && <span className={styles.errorText}>Name required</span>}

        </div>
        <div className={styles.inputContainer}>
          <label htmlFor="phone">Phone</label>
          <span className={styles.required}>*</span>
          <input type="number" placeholder='Enter Your Phone' {...register("phone", { required: true })} />
          {errors.phone && <span className={styles.errorText}>Phone required</span>}

        </div>
        <div className={styles.inputContainer}>
          <label htmlFor="email">Email</label>
          <span className={styles.required}>*</span>
          <input type="text" placeholder='Enter Your Email' {...register("email", { required: true })} />
          {errors.email && <span className={styles.errorText}>Email required</span>}

        </div>
        <div className={styles.inputContainer}>
          <label htmlFor="address">Address</label>
          <span className={styles.required}>*</span>
          <input type="text" placeholder='Enter Your Address' {...register("address", { required: true })} />
          {errors.address && <span className={styles.errorText}>Address required</span>}
        </div>
        <div className={styles.inputContainer}>
          <label htmlFor="message">Message</label>
          <span className={styles.required}>*</span>
          <textarea  placeholder='Enter Your Message' {...register("imessage", { required: true })} />
          {errors.imessage && <span className={styles.errorText}>Message required</span>}
        </div>

        <div className={styles.inputContainer}>
          <MyButton text='Send' type="submit" />
        </div>
      </form>
    </div>
  )
}

export default RequestForm