import { useContext, useEffect, useState } from 'react'
import styles from './styles.module.css'
import { FeaturesContext } from 'Context/FeaturesContext/FeaturesContext'
import { Link, useLocation } from 'react-router-dom'

const CourseLayout = ({ children }: any) => {
    const { courses } = useContext<any>(FeaturesContext)
    const [myCourse, setMyCourse] = useState<any>('')

    const { pathname } = useLocation()



    useEffect(() => {
        if (pathname && courses.length > 0 && pathname.split("/").length == 3) {
            let cc = courses.find((pd: any) => {
                return ((((pd.name.toLowerCase()).split(" ")).join("-")).split(",")).join("-") == pathname.split("/")[2]
            })
            if (cc) {
                setMyCourse(cc.id)
            }

        }
        else {
            setMyCourse("")
        }
    }, [pathname, courses, courses.length])

    return (
        <div>
            <div className={styles.courseBox}>
                <div className={styles.courseSlider}>
                    <h2 className="section-sub-header">Select Course</h2>
                    {
                        courses.map((mc: any, index: number) => {
                            return <Link
                            key={index}
                                className={`${styles.catHeader} ${myCourse == mc.id ? styles.selCatHead : ""}`}

                                to={`/courses/${(((mc?.name?.toLowerCase().split(" ")).join("-")).split(",")).join("-")}`}
                                >
                                {mc.name}
                                <span>+</span>

                            </Link>
                        })
                    }
                </div>
                {/* <div cla={styles.courseButtons}>
                    {}
                </div> */}
                <div className={styles.courseChildren}>
                    {children}
                </div>

            </div>

        </div>
    )
}

export default CourseLayout