import { useContext, useEffect, useState } from "react"
import { FeaturesContext } from "Context/FeaturesContext/FeaturesContext"
import { useParams } from "react-router-dom"
import CourseFeatures from "Components/CourseFeatures/Index"
import CourseAction from "Components/CourseAction/Index"
import CourseInfo from "Components/CourseInfo/Index"
import CourseDuration from "Components/CourseDuration/Index"


const CourseInner = () => {

    const params = useParams()
    const { courses } = useContext<any>(FeaturesContext)
    const [myCourses, setMyCourses] = useState<any>()

    useEffect(() => {
        if (params && params.name) {
            let cc = courses.find((pd: any) => {
                return ((((pd.name.toLowerCase()).split(" ")).join("-")).split(",")).join("-") == params.name
            })

            setMyCourses(cc)

        }
    }, [params, params.name])
    return (
        <main>
            {myCourses &&
                <CourseInfo myCourse={myCourses} />}
            {myCourses && myCourses.features && JSON.parse(myCourses.features)?.length>0 && <CourseFeatures myCourse={myCourses} />}
            
            {myCourses &&
                <CourseDuration myCourse={myCourses} />}
            {myCourses && <CourseAction myCourse={myCourses} />}
        </main>
    )
}

export default CourseInner