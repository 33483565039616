import MyButton from 'Components/MyButton/Index'
import styles from './styles.module.css'
import MyImage from 'Components/MyImage/Index'
import { server } from 'utils/index'
import { useEffect, useState } from 'react'

const CourseCard = ({ item: { images, name, duration } }: any) => {
  const [gotLink, setGoToLink] = useState('')

  useEffect(()=>{
    if(name){
      let k = name;
      let c = k.toLowerCase()
      let d = c.split(" ")
      let e = d.join("-")
      let f = e.split(",")
      let g = f.join("-")
      setGoToLink(g)
    }
  },[name])
  return (
    <div className={styles.courseBox}>
      <MyImage
        source={images ? JSON.parse(images) && `${server}/${JSON.parse(images)[0]}` : ""}ß
        alt="Course Image" />
      <div className={styles.cardDetail}>
        <div className={styles.cardDuration}>{duration}</div>
        <h2 className={styles.name}>{name}</h2>
        {/* <div className={styles.cardInfo}>
          {info}
        </div> */}
        <div className={styles.button}>
          <MyButton text='Learn More' link={true}
            href={`/courses/${gotLink}`}
          />
        </div>
      </div>
    </div>)
}

export default CourseCard