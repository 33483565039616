import { useContext, useEffect, useState } from 'react'
import styles from './styles.module.css'
import { FeaturesContext } from 'Context/FeaturesContext/FeaturesContext'
import { server } from 'utils/index'

const Mission = () => {
  const { sections } = useContext<any>(FeaturesContext)
  const [mySection, setMySection] = useState<any>()
  useEffect(() => {
    if (sections && sections.length > 0) {
      const cc = sections.find((sc: any) => sc.name.toLowerCase() == 'our mission')
      if (cc) {
        setMySection(cc)
      } else {
        setMySection(null)
      }
    }
  }, [sections, sections.length])
  return (
    <div className={styles.container}>
        <div className={styles.left}>
          <h2 className="section-heading">{mySection && mySection.heading}</h2>
          <p className="section-paragraph">
            {mySection && mySection.list && JSON.parse(mySection.list) && JSON.parse(mySection.list).data[0]?.paragraph}

          </p>
        </div>
        <div className={styles.right}>
        <img src={mySection && `${server}/${mySection.image}`} alt="" />
        </div>
    </div>
  )
}

export default Mission